import React, { useEffect } from 'react'
import './banner.css';
import banner_img1 from "../../../image//blog_10.jpg"
  
function Banner () {

  useEffect(() => {
    const imags = document.querySelectorAll("#imag")
    const imgs_array = Array.from(imags)
    const dots = document.querySelectorAll(".dot")
    const dots_array = Array.from(dots)

    function slider(){

      function add_one(){
        imgs_array[0].classList.add("show")
        dots_array [0].classList.add("active")
      }
      function add_two(){
        imgs_array[1].classList.add("show")
        dots_array [1].classList.add("active")
      }
      function add_three(){
        imgs_array[2].classList.add("show")
        dots_array [2].classList.add("active")
      }
      function add_four(){
        imgs_array[3].classList.add("show")
        dots_array [3].classList.add("active")
      }

      function removeShowOne(){
          if(imgs_array[0].classList.contains("show")){
            imgs_array[0].classList.remove('show')
            dots_array[0].classList.remove('active')
            imgs_array[0].classList.add('hide')
          }
      }
      function removeShowTwo(){
          if(imgs_array[1].classList.contains("show")){
            imgs_array[1].classList.remove('show')
            dots_array[1].classList.remove('active')
            imgs_array[1].classList.add('hide')
          }
      }
      function removeShowThree(){
          if(imgs_array[2].classList.contains("show")){
            imgs_array[2].classList.remove('show')
            dots_array[2].classList.remove('active')
            imgs_array[2].classList.add('hide')
          }
      }
      function removeShowFour(){
        if(imgs_array[3].classList.contains("show")){
          imgs_array[3].classList.remove('show')
          dots_array[3].classList.remove('active')
          imgs_array[3].classList.add('hide')
        }
      }

      function finalRemove(){
        imgs_array.forEach(element => {
          element.classList.remove("hide")
        });
      }
      add_one() 
      setTimeout(removeShowOne,5000)
      setTimeout(finalRemove,6000)
      setTimeout(add_two,4900)
      setTimeout(removeShowTwo,10000)
      setTimeout(finalRemove,11000)
      setTimeout(add_three,9900)
      setTimeout(removeShowThree,15000)
      setTimeout(finalRemove,16000)
      setTimeout(add_four,14900)
      setTimeout(removeShowFour,20000)
      setTimeout(finalRemove,21000)
    }
slider()
setInterval(slider,20030)
  })  
  
  return (
    <main className='banner'>
    <div className='banner-img'>
      <img id='imag'src={banner_img1} alt="" />
      <img id='imag'src={banner_img1} alt="" />
      <img id='imag'src={banner_img1} alt="" />
      <img id='imag'src={banner_img1} alt="" />
    </div>
    <div className="dots">
      <div className="dot one "></div>
      <div className="dot two"></div>
      <div className="dot three"></div>
      <div className="dot four"></div>
    </div>
    </main>
  )
}

export default Banner






