import React from 'react'
import img from '../../../image/download.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar ,faFilter } from '@fortawesome/free-solid-svg-icons'
function BestSeller() {
  return (
<main className='seller m' >
        <div className="title w">Offer 50% <FontAwesomeIcon icon={faFilter}/></div>
      <div className='seller-container'>
          <div className="best-seller">
            <div className="offer"><span>OFFER</span></div>
          <div className="seller-img"><img  className="i"src={img} alt="" /></div>
          <div div className='text'>
              <div className="heading"><h3>Iphon 14 Pro Max</h3></div>
              <div className="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. ipsa ullam facilis quo fugit cumque?</div>
              <div className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
              <div className="seller-price">
              <div className="price">$149.99</div>
              <div className="descount">$169.99</div>
            </div>
          </div>
        </div>
          <div className="best-seller">
          <div className="offer"><span>OFFER</span></div>
          <div className="seller-img"><img  className="i"src={img} alt="" /></div>
          <div div className='text'>
              <div className="heading"><h3>Iphon 14 Pro Max</h3></div>
              <div className="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. ipsa ullam facilis quo fugit cumque?</div>
              <div className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
              <div className="seller-price">
              <div className="price">$149.99</div>
              <div className="descount">$169.99</div>
            </div>
          </div>
        </div>
          <div className="best-seller">
          <div className="offer"><span>OFFER</span></div>
          <div className="seller-img"><img  className="i"src={img} alt="" /></div>
          <div div className='text'>
              <div className="heading"><h3>Iphon 14 Pro Max</h3></div>
              <div className="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. ipsa ullam facilis quo fugit cumque?</div>
              <div className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
              <div className="seller-price">
              <div className="price">$149.99</div>
              <div className="descount">$169.99</div>
            </div>
          </div>
        </div>
          <div className="best-seller">
          <div className="offer"><span>OFFER</span></div>
          <div className="seller-img"><img  className="i"src={img} alt="" /></div>
          <div div className='text'>
              <div className="heading"><h3>Iphon 14 Pro Max</h3></div>
              <div className="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. ipsa ullam facilis quo fugit cumque?</div>
              <div className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
              <div className="seller-price">
              <div className="price">$149.99</div>
              <div className="descount">$169.99</div>
            </div>
          </div>
        </div>
          <div className="best-seller">
          <div className="offer"><span>OFFER</span></div>
          <div className="seller-img"><img  className="i"src={img} alt="" /></div>
          <div div className='text'>
              <div className="heading"><h3>Iphon 14 Pro Max</h3></div>
              <div className="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. ipsa ullam facilis quo fugit cumque?</div>
              <div className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
              <div className="seller-price">
              <div className="price">$149.99</div>
              <div className="descount">$169.99</div>
            </div>
          </div>
        </div>
      </div>
</main>
  )
}

export default BestSeller