import React from 'react'
import './seller.css';
import img from '../../../image/download.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar ,faCrown,faStarHalf } from '@fortawesome/free-solid-svg-icons'
import data from '../../../Api/product.json'
function BestSeller() {
  return (
    <>
<div className='seller' >
        <div className="title">Top 10 best sellers <FontAwesomeIcon icon={faCrown}/></div>
      <div className='seller-container'>
      {
        data.bestseller.map((product)=>{
          return(
          <div  className="best-seller" key={product.id}>
            <div className="order">{product.id}</div>
          <div className="seller-img"><img src={img} alt="" /></div>
          <div div className='text'>
              <div className="heading"><h3>{product.title}</h3></div>
              <div className="paragraph">{product.description}</div>
              <div className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
              <div className="seller-price">
              <div className="price">{product.price}</div>
              <div className="descount">{product.discount}</div>
            </div>
          </div>
        </div>
          )
        })
      }





          {/* <div  className="best-seller">
            <div className="order">1</div>
          <div className="seller-img"><img src={img} alt="" /></div>
          <div div className='text'>
              <div className="heading"><h3>Iphon 14 Pro Max</h3></div>
              <div className="paragraph">Lorem ipsum dolor sit amet consectetur adipisicing elit. ipsa ullam facilis quo fugit cumque?</div>
              <div className="rating"><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/><FontAwesomeIcon icon={faStar}/></div>
              <div className="seller-price">
              <div className="price">$149.99</div>
              <div className="descount">$169.99</div>
            </div>
          </div>
        </div>  */}
      </div>
</div>  
  </>
  )
}

export default BestSeller