import './App.css';
import Nav from "./component/navbar/nav"
import Banner from "./component/content/banner/banner"
import Products from './component/content/products/products'
import BestSeller from './component/content/best seller/seller'
function App() {
  return (
    <div className="App">
      <Nav/>
      <Banner/>
      <BestSeller/>
      <Products/>
    </div>
  );
}

export default App;
