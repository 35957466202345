import React, { useEffect , useState} from 'react'
import './control.css';
import './products.css';
import Offer from '../offer/offer'
import icon from '../../../image/icon-cart.svg'
import img from '../../../image/download.jpeg'
import data from '../../../Api/product.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faSearch,
  faFilter,
  faSort,
  faListSquares ,
  faComputer ,
  faLaptop ,
  faMobile,
  faSolarPanel,
  faKeyboard,
  faListUl,
  faTable
} from '@fortawesome/free-solid-svg-icons'
  function Products() {
    const [searchTerm , setSearchTerm] = useState("")

    useEffect(()=>{
    const section = document.querySelector(".controls")
    const control = document.querySelector(".control")
    window.onscroll = function (){
      if(window.scrollY >= section.offsetTop){
        control.classList.add("p-fixed")
      }else{
        control.classList.remove("p-fixed")
      }
    }
    })
    
    return (
      <>
  <div className='controls'>
      <div className="control ">
        <div className="search-box">
      
        <input type="text" name="" id="search" placeholder='Search For Products'
        onChange={(e)=>{setSearchTerm(e.target.value)}}
        />
        <button><FontAwesomeIcon icon={faSearch}/>Search</button>
      </div>
        <div className="filters">Filter<FontAwesomeIcon icon={faFilter}/>
        <div className="filter-menu">
          <div className="filter f-one">Latest</div>
          <div className="filter f-two">Offers 50%</div>
          <div className="filter f-three">Descount</div>
          <div className="filter f-four">Top Rated</div>
        </div>
        </div>
        <div className="sorting" >Sort<FontAwesomeIcon icon={faSort}/>
          <div className="sort-menu">
            <div className="sort s-one">
              <span>Lowest Price</span>
            </div>
            <div className="sort s-two">
              <span>Highest Price</span>
            </div>
            <div className="sort s-three">
                <span>Categories</span>
                <div className="submenu">
                  <span><FontAwesomeIcon icon={faComputer}/>Pc</span>
                  <span><FontAwesomeIcon icon={faLaptop}/>Laptop</span>
                  <span><FontAwesomeIcon icon={faMobile}/>Mobile</span>
                  <span><FontAwesomeIcon icon={faSolarPanel}/>Monitor</span>
                  <span><FontAwesomeIcon icon={faKeyboard}/>Accessoris</span>
                </div>
              </div>
          </div>
        </div>
        <div className="viewer">View<FontAwesomeIcon icon={faListSquares}/>
          <div className="view-menu">
            <div className="view f-one"><FontAwesomeIcon icon={faListUl}/>List</div>
            <div className="view f-two"><FontAwesomeIcon icon={faTable}/>Compact</div>
          </div>
        </div>
      </div>
    </div>
      <section className='products'>
      {
        data.product.filter((val)=>{
          if(searchTerm == ""){
            return val;
          }else if(val.title.toLowerCase().includes(searchTerm.toLowerCase())){
            return val;
          }
        })
        .map((product)=>{
          return(
        <div className="product" key={product.id}>
          <div className="img"> <img src={img} alt="" />  </div>
          <div className="text">
            <div className="category">{product.category}</div>
            <div className="heading">
              <h1>{product.title}</h1>
            </div>
            <div className="p-container">
            {product.description}
            </div>
            <div className="price-container">
              <div className="price">{product.price}</div>
              <div className="descount">{product.discount}</div>
            </div>
          <button className='btn'><img src={icon} alt="" /> Add to Cart</button>
          </div>
        </div>
          )
        })
      }
      <Offer/>
      </section>
      </>
    )
  }
  export default Products
  
  {/* <div className="product">
    <div className="img"> <img src={img} alt="" />  </div>
    <div className="text">
      <div className="category">Mobile</div>
      <div className="heading">
        <h1>Iphone 14 Pro Max</h1>
      </div>
      <div className="p-container">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde praesentium dolorem quod, asperiores laborum facere voluptatum vero nostrum assumenda expedita voluptate placeat totam adipisci, vitae in ullam omnis debitis? Accusantium!
      </div>
      <div className="price-container">
        <div className="price">$149.99</div>
        <div className="descount">$169.99</div>
      </div>
    <button className='btn'><img src={icon} alt="" /> Add to Cart</button>
    </div>
  </div> */}

    // useEffect(()=>{
    //   const input = document.querySelector("#search").value.toUpperCase();
    //   const product = document.querySelectorAll(".product");
    //   const productName = document.getElementsByTagName("h1");
    // })
      // const searchValue = input.value.toUpperCase();

      // for(let i = 0; i<productName.length; i++){
      //   if(productName[i].innerHTML.toUpperCase().indexOf(input.toUpperCase()) >= 0){
      //     product[i].style.display = "";
      //   }else{
      //     product[i].style.display ="none";
      //   }
      // }



            // let arry = Array.from(productName)
      // arry.map((e)=>{
      //   // Array.from(e.innerHTML)

      //   let arr ;
      //   console.log(arr);
      // })
// for(let i = 0; i <= product.length - 1; i++){
//   console.log(productName[i]);
// }


      // function search () {
      //   input.oninput = ()=>{
      //     console.log(input.value.toUpperCase());
      //     console.log(productName.innerHTML.toUpperCase()[input.value.length - 1]);
      //   }
      // }
      // search()

            // for(let i = 0; i<productName.length; i++){
      //   if(productName[i].innerHTML.toUpperCase().indexOf(input) >= 0){
      //     product[i].style.display = "";
      //   }else{
      //     product[i].style.display ="none";
      //   }
      // }

