import React from 'react'
import { Link } from 'react-router-dom';
import './nav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStoreAlt ,faSearch } from '@fortawesome/free-solid-svg-icons'
const nav = () => {
  return (
    <main className='nav'>
    <div className="nav-group">
      <div className="logo">
      <FontAwesomeIcon icon={faStoreAlt}/>
      </div>
      {/* <div className="search-box">
      <FontAwesomeIcon icon={faSearch}/>
        <input type="search" name="" id="" placeholder='Search' />
      </div> */}
    </div>
    <div className="links">
      <ul>
        <li><Link className='link' to="/">Home</Link></li>
        <li><Link className='link' to="/">Home</Link></li>
        <li><Link className='link' to="/">Home</Link></li>
        <li><Link className='link' to="/">Home</Link></li>
        <li><Link className='link' to="/">Home</Link></li>
      </ul>
    </div>
    </main>
  )
}

export default nav